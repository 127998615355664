<template>
  <DateFormat v-if="doseTime" :value="doseTime" :format="hourFormatString" />
  <v-chip
    v-else
    label
    small
    color="#FDF7E6"
    text-color="#333"
    class="width-100-pct align-center justify-center"
    data-testid="bmg-chip-msg"
  >
    <span
      :class="{
        'deleted-dose-color': isDeleted,
      }"
    >
      {{ landscape ? 'Unknown time' : 'Unknown' }}
    </span>
  </v-chip>
</template>
<script>
import { format24h } from '@/utils/date/hourFormatter'
import DateFormat from '../DateFormat'

export default {
  name: 'InsulinDiaryDoseTime',
  components: {
    DateFormat,
  },
  props: {
    doseTime: { type: [Object, String, Date], default: null },
    hourFormatString: {
      type: String,
      required: true,
      default: format24h,
    },
    landscape: { type: Boolean, required: true },
    isDeleted: { type: Boolean, required: false, default: false },
  },
}
</script>
